module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-clerk/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":660,"showCaptions":["title"],"markdownCaptions":true,"withWebp":true,"linkImagesToOriginal":false,"backgroundColor":"transparent","quality":50,"withAvif":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Robiolab","short_name":"Robiolab","start_url":"/","background_color":"#FFFFFF","theme_color":"#000000","display":"minimal-ui","icon":"./content/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"79267fd1027eb62e97557d1780e974df"},
    },{
      plugin: require('../node_modules/gatsby-plugin-gdpr-cookies/gatsby-browser.js'),
      options: {"plugins":[],"googleAnalytics":{"trackingId":"G-W89KX7918Z","cookieName":"gatsby-gdpr-google-analytics","anonymize":true},"environments":[["production","development"],"development"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/robiolab_theme/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://www.robiolab.co","manifestSettings":{"favicon":"./content/images/favicon.png","siteName":"Robiolab","shortName":"Robiolab","startUrl":"/","backgroundColor":"#FFFFFF","themeColor":"#000000","display":"minimal-ui"},"contentDirectory":"./content","blogSettings":{"path":"/blog","usePathPrefixForArticles":true,"entityName":"Artículo"},"projectSettings":{"path":"/projects","usePathPrefixForArticles":true,"entityName":"Proyecto"},"googleAnalytics":{"trackingId":"G-W89KX7918Z","anonymize":true,"environments":["production","development"]}},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
